import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, View } from "react-native";
import AddressValidator from "./Screens/Address";
import PickupCompleted from "./Screens/complete";
import Welcome from "./Screens/welcome";
import Dashboard from "./Screens/businessDash";
import SignUp from "./Screens/signUp";
import CreateShipment from "./Screens/dashboardTools/createShipment";
import TrackShipment from "./Screens/dashboardTools/trackShipment";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
const Stack = createStackNavigator();

export default function App() {
  return (
    <BrowserRouter style={{ width: "100%" }}>
      <Routes style={{ width: "100%" }}>
        <Route path="/" element={<Welcome />} />
        <Route path="/Welcome" element={<Welcome />} />
        <Route path="return/:id" element={<AddressValidator />} />
        <Route path="/complete" element={<PickupCompleted />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/createshipment" element={<CreateShipment />} />
        <Route path="/trackshipment" element={<TrackShipment />} />

      </Routes>
    </BrowserRouter>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
