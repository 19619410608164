import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  StyleSheet,
  TextInput,
  ImageBackground,
} from "react-native";
import { AsyncStorage } from "react-native";
import { TouchableOpacity, Animated } from "react-native";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SignUp = ({ history }) => {
  const image = { uri: "https://reactjs.org/logo-og.png" };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const authorizeUser = async () => {
    try {
      const response = await axios.post(
        "https://xidm-bhau-tcuw.n7.xano.io/api:hzahvnDj:v1/auth/signup",
        {
          email: email,
          password: password,
          name: name,
          addressLine: addressLine,
          city: city,
          state: state,
          zip: zip,
          phone: phone,
        }
      );
      console.log(response.data);
      window.localStorage.setItem("authToken", response.data.authToken);
      const data = window.localStorage.getItem("authToken");
      console.log(data);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("authToken") !== null) {
      navigate("/dashboard");
    }
  });
  const navReturns = () => {
    navigate("/return");
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Create Account</Text>
      <Text style={styles.subtitle}>Kothara</Text>
      <View style={styles.form}>
        <TextInput
          placeholder="Full Name"
          value={name}
          onChangeText={setName}
          style={[styles.input, { marginTop: 20 }]}
          placeholderTextColor="#999"
        />
        <TextInput
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          style={[styles.input]}
          placeholderTextColor="#999"
        />
        <TextInput
          placeholder="Password"
          value={password}
          secureTextEntry={true}
          onChangeText={setPassword}
          style={[styles.input]}
          placeholderTextColor="#999"
        />
        <TextInput
          placeholder="Phone Number"
          value={phone}
          onChangeText={setPhone}
          style={[styles.input]}
          placeholderTextColor="#999"
        />
        <TextInput
          placeholder="Address Line"
          value={addressLine}
          onChangeText={setAddressLine}
          style={[styles.input]}
          placeholderTextColor="#999"
        />
        <View style={styles.inputContainer}>
          <TextInput
            placeholder="City"
            value={city}
            onChangeText={setCity}
            style={[styles.input, { width: "30%" }]}
            placeholderTextColor="#999"
          />
          <TextInput
            placeholder="State"
            value={state}
            onChangeText={setState}
            style={[styles.input, { width: "30%" }]}
            placeholderTextColor="#999"
          />
          <TextInput
            placeholder="Zip"
            value={zip}
            onChangeText={setZip}
            style={[styles.input, { width: "30%" }]}
            placeholderTextColor="#999"
          />
        </View>
        <TouchableOpacity style={styles.button} onPress={authorizeUser}>
          <Text style={styles.buttonText}>Sign Up</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: 24,
    marginTop: 0,
    color: "#333",
  },
  subtitle: {
    fontSize: 60,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  form: {
    width: "100%",
    maxWidth: 350,
  },
  input: {
    backgroundColor: "#FFFFFF",
    padding: 15,
    marginVertical: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#333",
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
  },
  button: {
    backgroundColor: "#007AFF",
  marginTop: 20,
  borderRadius: 10,
  padding: 15,
  alignItems: "center",
  },
  buttonText: {
  color: "#FFFFFF",
  fontSize: 18,
  fontWeight: "bold",
  },
  });

export default SignUp;
