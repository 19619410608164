import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  StyleSheet,
  TextInput,
  ImageBackground,
} from "react-native";
import { AsyncStorage } from "react-native";
import { TouchableOpacity, Animated } from "react-native";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Welcome = ({ history }) => {
  const image = { uri: "https://reactjs.org/logo-og.png" };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const authorizeUser = async () => {
    try {
      const response = await axios.post(
        "https://xidm-bhau-tcuw.n7.xano.io/api:hzahvnDj:v1/auth/login",
        {
          email: email,
          password: password,
        }
      );
      console.log(response.data);
      window.localStorage.setItem("authToken", response.data.authToken);
      const data = window.localStorage.getItem("authToken");
      console.log(data);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("authToken") !== null) {
      navigate("/dashboard");
    }
  });
  const navReturns = () => {
    navigate("/return");
  };
  const navSignUp = () => {
    navigate("/signup");
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Welcome to</Text>
      <Text style={styles.subtitle}>Kothara</Text>
      <Text style={[styles.title, {marginTop: -10, fontSize: 12}]}>a Sylver product</Text>
      <View style={styles.form}>
        <TextInput
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          style={[styles.input, { marginTop: 20 }]}
          placeholderTextColor="#999"
        />
        <TextInput
          placeholder="Password"
          value={password}
          secureTextEntry={true}
          onChangeText={setPassword}
          style={[styles.input]}
          placeholderTextColor="#999"
        />
        <TouchableOpacity style={styles.button} onPress={authorizeUser}>
          <Text style={styles.buttonText}>Login</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={navSignUp}>
          <Text style={styles.link}>New to Kothara? <Text style={styles.linkBold}>Sign Up</Text></Text>
        </TouchableOpacity>
      </View>
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: 24,
    color: "#333",
  },
  subtitle: {
    fontSize: 60,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  form: {
    width: 350,
    maxWidth: "90%",
  },
  input: {
    backgroundColor: "#FFFFFF",
    padding: 15,
    marginVertical: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#333",
  },
  button: {
    backgroundColor: "#007AFF",
    marginTop: 20,
    borderRadius: 10,
    paddingVertical: 12,
    marginBottom: 20,
    alignItems: "center",
  },
  buttonText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  link: {
    fontSize: 16,
    color: "#007AFF",
    textAlign: "center",
    marginTop: 10,
  },
  linkBold: {
    fontWeight: "bold",
  },
});

export default Welcome;
