import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  StyleSheet,
  TextInput,
  Image,
  ImageBackground,
  Dimensions,
} from "react-native";
import { AsyncStorage } from "react-native";
import { TouchableOpacity, Animated } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { MaterialIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";

import { useNavigate } from "react-router-dom";
import axios from "axios";

const Dashboard = ({ history }) => {
  const [isPhone, setIsPhone] = useState(false);
  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    setIsPhone(width < 768); // Set isPhone to true if the screen width is less than 768 (i.e. phone size)
  }, [width]);
  const image = { uri: "https://reactjs.org/logo-og.png" };
  const [email, setEmail] = useState("");
  const [dataResponse, setDataResponse] = useState([]);
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const data = window.localStorage.getItem("authToken");
  console.log(data);
  const navigate = useNavigate();
  const logOut = async () => {
    localStorage.clear();
    navigate("/");
  };

  const fetchData = async () => {
    const result = await axios({
      method: "post",
      url:
        "https://xidm-bhau-tcuw.n7.xano.io/api:hzahvnDj:v1/account-history",
      headers: {
        Authorization: String(window.localStorage.getItem("authToken")),
      },
    }).catch((error) => {
      console.error(error);
      localStorage.clear();
      navigate("/");
    });
    console.log(result.data);
    setDataResponse(result.data);
    setEmail(result.data.email);
    setName(result.data.name);
    setBalance(result.data.balance);
    setPhone(result.data.phone);
    setAddress(result.data.AddressLine);
    setCity(result.data.City);
    setState(result.data.State);
    setZip(result.data.Zip);
  };
  const balancePress = async () => {
    try {
      const newWindow = window.open("", "_blank");
      
      const response = await axios.get("https://xidm-bhau-tcuw.n7.xano.io/api:-B-RHqob/reload", {
        headers: {
          Authorization: String(window.localStorage.getItem("authToken")),
        },
      });
  
      const paymentUrl = response.data.payment_link.url;
      
      if (paymentUrl) {
        newWindow.location = paymentUrl;
      } else {
        newWindow.close();
        alert("Failed to retrieve payment URL.");
      }
    } catch (error) {
      if (newWindow) {
        newWindow.close();
      }
      console.error(error);
      alert("An error occurred while processing your request.");
    }
  };
  const purchaseRebox = async () => {
    alert("Email us at cchorlog@onestone.org to purchase Rebox.");
  };

  useEffect(() => {
    if (window.localStorage.getItem("authToken") === null) {
      navigate("/");
    }
    fetchData();
  }, []);
  const createShipment = async () => {
    navigate("/createshipment");
  };
  const trackShipment = async () => {
    navigate("/trackshipment");
  };

  // Rest of the code stays the same as before

  return (
    <View style={styles.container}>
  <View style={isPhone ? styles.formPhone : styles.formDesktop}>
    <Text style={styles.title}>Dashboard</Text>
    <Text style={styles.subtitle}>Welcome back {name}!</Text>
    <View style={styles.actions}>
      <View style={styles.actionRow}>
        <TouchableOpacity onPress={createShipment} style={styles.actionItem}>
          <View style={styles.item}>
            <Entypo name="newsletter" size={32} color="black" />
            <Text style={styles.itemText}>New Shipment</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={trackShipment} style={styles.actionItem}>
          <View style={styles.item}>
            <MaterialIcons name="track-changes" size={32} color="black" />
            <Text style={styles.itemText}>Track Shipment</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.actionRow}>
        <TouchableOpacity onPress={purchaseRebox} style={styles.actionItem}>
          <View style={styles.item}>
            <MaterialIcons name="add-box" size={32} color="black" />
            <Text style={styles.itemText}>Purchase Rebox</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={balancePress} style={styles.actionItem}>
          <View style={styles.item}>
            <MaterialIcons name="attach-money" size={32} color="black" />
            <Text style={styles.itemText}>Balance: ${(Math.round(balance * 100) / 100).toFixed(2)}</Text>
          </View>
        </TouchableOpacity>
      </View>
      
      <TouchableOpacity onPress={logOut} style={styles.actionItem}>
        <View style={styles.item}>
          <Entypo name="log-out" size={30} color="black" />
          <Text style={styles.itemText}>Log out</Text>
        </View>
      </TouchableOpacity>
      <Text style={isPhone ? styles.infoPhone : styles.infoDesktop}>Account Information</Text>
      <View style={isPhone ? styles.accountInfoPhone : styles.accountInfoDesktop}>
        <Text style={styles.accountText}>Shipping From</Text>
        <Text style={styles.accountText}>{address.toUpperCase()}</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.accountText}>{city.toUpperCase()} </Text>
          <Text style={styles.accountText}>{state.toUpperCase()},</Text>
          <Text style={styles.accountText}> {zip.toUpperCase()}</Text>
        </View>
      </View>
      <View style={isPhone ? styles.tokenContainerPhone : styles.tokenContainerDesktop}>
        <Text style={isPhone ? styles.tokenTitlePhone : styles.tokenTitleDesktop}>Your Auth Token</Text>
        <Text style={isPhone ? styles.tokenInfoPhone : styles.tokenInfoDesktop}>{data}</Text>
      </View>
    </View>
  </View>
  <Text>Rebox is a product of Kothara, a division of Sylver LLC.</Text>
</View>


);
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "top",
    paddingTop: 40,
    backgroundColor: "#F9F9F9",
    padding: 20,
  },
  formPhone: {
    width: "100%",
    backgroundColor: "#F9F9F9",
    padding: 20,
    borderRadius: 10,
  },
  formDesktop: {
    width:"50%",
    backgroundColor: "#F9F9F9",
    padding: 20,
    borderRadius: 10,
    },
    title: {
    fontWeight: "bold",
    fontSize: 30,
    marginBottom: 5,
    color: "#333",
    },
    subtitle: {
    fontWeight: "400",
    fontSize: 18,
    marginTop: 10,
    color: "#777",
    },
    actions: {
    marginTop: 15,
    },
    actionRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    },
    actionItem: {
    width: "48%",
    },
    item: {
    flexDirection: "row",
    
    justifyContent: "space-between",
    backgroundColor: "white",
    alignItems: "center",
    padding: 15,
    borderRadius: 10,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    },
    itemText: {
    fontWeight: "bold",
    fontSize: 15,
    textAlign: "center",
    },
    infoPhone: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 20,
    textAlign: "center",
    },
    infoDesktop: {
    fontWeight: "bold",
    fontSize: 25,
    marginTop: 20,
    textAlign: "center",
    },
    accountInfoPhone: {
    width: "100%",
    marginTop: 10,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    },
    accountInfoDesktop: {
    width: "100%",
    marginTop: 10,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    },
    accountText: {
    fontSize: 15,
    fontWeight: "bold",
    },
    tokenContainerPhone: {
    marginTop: 20,
    backgroundColor: "white",
    padding: 10,
    borderRadius:10,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    },
    tokenContainerDesktop: {
    marginTop: 20,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    },
    tokenTitlePhone: {
    fontWeight: "bold",
    fontSize: 15,
    },
    tokenTitleDesktop: {
    fontWeight: "bold",
    fontSize: 20,
    },
    tokenInfoPhone: {
    fontWeight: "bold",
    fontSize: 8,
    marginTop: 5,
    },
    tokenInfoDesktop: {
    fontWeight: "bold",
    fontSize: 12,
    marginTop: 10,
    },
    });


export default Dashboard;
