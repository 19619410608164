import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Animated, StyleSheet, Image } from 'react-native';
import truckImage from '../assets/truck.png';

const PickupCompleted = () => {
  const [bounceValue] = useState(new Animated.Value(0));
  const bounceUpAndDown = () => {
    Animated.sequence([
      Animated.spring(bounceValue, { toValue: -10, friction: 2 }),
      Animated.spring(bounceValue, { toValue: -5, friction: 2 }),
    ]).start(() => bounceUpAndDown());
  };

  useEffect(() => {
    bounceUpAndDown();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Rebox</Text>
        <Text style={styles.subtitle}>What's Next?</Text>
      </View>
      <Animated.View style={[styles.imageWrapper, { transform: [{ translateY: bounceValue }] }]}>
        <Image source={truckImage} style={styles.truckImage} />
      </Animated.View>
      <View style={styles.divider}></View>
      <Text style={styles.boldText}>We have scheduled a pickup of Rebox</Text>
      <Text style={styles.instructionText}>
        Please fully compact rebox by putting all of the expanders inside the box. Place rebox near the front door of your house. USPS will pick up the parcel on the next business day.
      </Text>
      <View style={styles.paginationDots}></View>
      <Text style={styles.centeredBoldText}>Make Sure it is Visible!</Text>
      <Text style={styles.centeredBoldText}>Don't forget to waive at the mailman!</Text>
      <View style={styles.smallPaginationDots}></View>
      
      <Text>Rebox is a product of Kothara, a division of Sylver LLC.</Text>
      <Text style={styles.thankYouText}></Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    alignContent: "center",
    alignSelf: "center",
    width: '100%',
    maxWidth: 400,
    paddingTop: 20,
    paddingHorizontal: 20,
    backgroundColor: 'white',
  },
  header: {
    width: '100%',
    marginBottom: 40,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#333',
  },
  subtitle: {
    fontSize: 35,
    fontWeight: 'bold',
    color: '#333',
  },
  imageWrapper: {
    height: 200,
    marginBottom: 40,
  },
  truckImage: {
    width: 250,
    height: 250,
  },
  divider: {
    width: '80%',
    height: 1,
    backgroundColor: '#E0E0E0',
    borderRadius: 2,
    marginBottom: 40,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 25,
    width: '100%',
    marginBottom: 20,
    color: '#333',
  },
  instructionText: {
    width: '100%',
    fontSize: 18,
    marginTop: 5,
    marginBottom: 15,
    color: '#555',
  },
  paginationDots: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
  },
  centeredBoldText: {
    fontWeight: 'bold',
width: '100%',
marginTop: 5,
fontSize: 18,
textAlign: 'center',
color: '#333',
},
smallPaginationDots: {
flexDirection: 'row',
justifyContent: 'center',
marginVertical: 8,
},
madeWithLove: {
fontSize: 14,
marginTop: 20,
marginBottom: 10,
color: '#777',
},
thankYouText: {
fontSize: 16,
marginTop: 20,
},
dot: {
width: 8,
height: 8,
borderRadius: 8,
backgroundColor: '#E0E0E0',
marginHorizontal: 4,
},
smallDot: {
width: 4,
height: 4,
borderRadius: 4,
backgroundColor: '#E0E0E0',
marginHorizontal: 4,
},
});

export default PickupCompleted;
   
